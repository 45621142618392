

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyCkbfXYqxw7ygo-XxfAt866Yja4jNs31Po",
    authDomain: "web3auth-firebase.firebaseapp.com",
    projectId: "web3auth-firebase",
    storageBucket: "web3auth-firebase.appspot.com",
    messagingSenderId: "707643268846",
    appId: "1:707643268846:web:6d92e69726852a38e39dff",
    measurementId: "G-V631CSBMVY"
};
